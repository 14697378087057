import request from '@/utils/request'
const basrUrl = '/creative'
// 获取先投后审-列表
export function showedPager (data) {
  return request({
    url: `${basrUrl}/showedPager`,
    method: 'post',
    data
  })
}
// 先投后审-审核
export function auditShowed (data) {
  return request({
    url: `${basrUrl}/auditShowed`,
    method: 'post',
    data
  })
}
export function updateTagsShowed (data) {
  return request({
    url: `${basrUrl}/updateTagsShowed`,
    method: 'post',
    data
  })
}
// 先投后审-公司下拉框
export function listByCreative () {
  return request({
    url: '/user/user_list_by_creative_showed',
    method: 'get'
  })
}
// 先投后审-审核列表
export function preLaunchReport (data) {
  return request({
    url: '/creative/preLaunchReport',
    method: 'post',
    data
  })
}
