<template>
  <div>
    <div class="review-table-container">
      <div class="table">
        <a-table
          class="m_table_cust review-table"
          size="middle"
          :pagination="false"
          :rowKey="(record, index) => index"
          :data-source="tableList"
          tableLayout="fixed"
          :loading="isLoading"
          bordered
        >
          <!-- 日期 -->
          <a-table-column align="left" dataIndex="date" title="日期" width="200px"></a-table-column>
          <!-- 审核人 -->
          <a-table-column v-if="showAudit" align="left" dataIndex="username" title="审核人" width="200px">
          </a-table-column>
          <!-- 通过数 -->
          <a-table-column align="left" dataIndex="passNum" title="通过数" width="200px"> </a-table-column>
          <!-- 拒绝数 -->
          <a-table-column align="left" dataIndex="rejectNum" title="拒绝数" width="200px"> </a-table-column>
        </a-table>
      </div>
      <a-pagination
        class="pagination"
        v-if="tableList?.length > 0"
        show-size-changer
        :current="query.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleCurrentPage"
        @showSizeChange="handleChangePageSize"
        :pageSize="query.limit"
      />
      <m-empty v-if="tableList?.length === 0" style="height: 500px">
        <template #description>暂无数据 </template>
      </m-empty>
    </div>
  </div>
</template>

<script>
import { preLaunchReport } from '@/api/review'
export default {
  name: 'ReviewTable',
  props: {
    arr: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isLoading: false,
      // 审核列表
      showAudit: false,
      total: undefined,
      query: {
        page: 1,
        limit: 10
      },
      tableList: []
    }
  },
  methods: {
    // table数据
    async getTable () {
      this.isLoading = true
      const { userId, auditUsers, beginDate, endDate } = this.arr
      const param = { userId, auditUsers, beginDate, endDate, ...this.query }
      const { data = [] } = await preLaunchReport(param)
      this.tableList = data.items
      this.total = data.total
      // 审核列表
      auditUsers.length > 0 ? (this.showAudit = true) : (this.showAudit = false)
      this.isLoading = false
    },
    // 分页
    handleCurrentPage (page) {
      this.query.page = page
      this.getTable()
    },
    handleChangePageSize (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getTable()
    }
  }
}
</script>

<style lang="less" scoped>
.review-table-container {
  margin-top: 10px;
  border-radius: 5px;
  background: #fff;
  .table {
    display: flex;
    padding: 20px;
    .review-table {
      overflow: hidden;
      width: 100%;
      position: relative;
    }
    .ant-table-fixed-left table,
    .ant-table-fixed-right-right table {
      width: min-content;
    }
  }
  .pagination {
    padding: 10px 10px;
  }
}
</style>
